import React  , {lazy , Suspense , useEffect}  from 'react';
import {BrowserRouter as Router , Switch , Route} from 'react-router-dom'
import './css/app.css'

const Home = lazy(() => import('./component/Home/Home.js'));
const About = lazy(() => import('./component/About/About.js'));
const Page = lazy(() => import('./component/Subject/Page.js'));
const Head = lazy(() => import('./component/Nav/Head.js'));
const Form = lazy(() => import('./component/Home/Form/Form.js'));
const Artical = lazy(() => import('./component/Subject/Artical.js'));
const Recommends = lazy(() => import('./component/Recommends/Recommends.js'));
const Articles = lazy(() => import('./component/Articles/Articles.js'));
const ContactPage = lazy(() => import('./component/Contact/Contact.js'));

function App() {
  const path = ''

  return <Suspense fallback={``}>
    <Router >
      <main>
        <Switch>
          <Route path={`${path}/`} exact component={Home}/>

          <Route path={`${path}/about`} exact component={()=><>
          <Head />
          <About />
          </>}/>
          
          <Route path={`${path}/family`} exact component={()=><>
          <Head />
          <Page subject={`family`} />
          <Form className={`static`}/>
          </>} />
          <Route path={`${path}/family/:name`} exact component={()=><Artical type={1} />} />
          <Route path={`${path}/crime/:name`} exact component={()=><Artical type={2} />} />

          <Route path={`${path}/crime`} exact component={()=><>
          <Head />
          <Page subject={`crime`} />
          <Form className={`static`}/>
          </>} />

          <Route path={`${path}/work`} exact component={()=><>
          <Head />
          <Page subject={`work`} />
          <Form className={`static`}/>
          </>} />

          <Route path={`${path}/recommends`} exact component={()=><>
            <Recommends />
          </>} />
          <Route path={`${path}/articles`} exact component={()=><>
            <Articles />
          </>} />
          <Route path={`${path}/contact`} exact component={()=><>
          <Head />
          <ContactPage />
          </>}/>
        </Switch>

      </main>
    </Router >

    </Suspense>
}

export default App;
